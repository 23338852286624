import {isNotNilOrEmpty} from "ramda-adjunct";
import {prop} from "ramda";

export const sendMAEventIfDefined = ma => {
  if (isNotNilOrEmpty(ma)) {
    window.dataLayer.push({
      event: 'custom_event',
      eventCategory: prop('category', ma),
      eventAction: prop('action', ma),
      eventName: prop('name', ma)
    });
  }
};
export const configMA = {
  CONTRACTS_HEALTH: {
    action: 'Complémentaire santé',
    category: 'Contrats',
    name: 'click_complementary_health'
  },
  CONTRACTS_PENSION_HOSPI: {
    action: 'Prévoyance',
    category: 'Contrats',
    name: 'click_provident_insurance'
  },
  CONTRACTS_ASSISTANCE: {
    action: 'Contrat Assistance',
    category: 'Contrats',
    name: 'click_contract_assistance'
  },
  CONTRACT_HEALTH_UPDATE_CONTRACT: {
    action: 'Santé modification contrat',
    category: 'Contrat',
    name: 'click_modification_contract_health'
  },
  CONTRACT_HEALTH_REFUNDS: {
    action: 'Santé remboursement santé',
    category: 'Contrat',
    name: 'click_refund_health'
  },
  CONTRACT_HEALTH_CONTACT_ASSISTANCE: {
    action: 'Santé contact assistance',
    category: 'Contrat',
    name: 'click_contact_assistance'
  },
  CONTRACT_HEALTH_ASSISTANCE_METRO: {
    action: 'Santé Europ Assistance France',
    category: 'Contrat',
    name: 'click_contact_number_assistance_Fr'
  },
  CONTRACT_HEALTH_ASSISTANCE_DROM: {
    action: 'Santé Europ Assistance DROM',
    category: 'Contrat',
    name: 'click_contact_number_assistance_DROM'
  },
  CONTRACT_HEALTH_BENEFICIARIES: {
    category: 'Contrat',
    action: 'Santé consulter bénéficiaires',
    name: 'click_beneficiary'
  },
  CONTRACT_HEALTH_UPDATE_BENEFICIARIES: {
    action: 'Santé modifier bénéficiaire',
    category: 'Contrat',
    name: 'click_modification_beneficiary'
  },
  CONTRACT_PENSION_HOSPI_UPDATE_CONTRACT: {
    action: 'Prévoyance modification contrat',
    category: 'Contrat',
    name: 'click_modification_contract_provident_insurance'
  },
  CONTRACT_PENSION_HOSPI_DECLARE: {
    action: 'Prévoyance déclaration sinistres',
    category: 'Contrat',
    name: 'click_accident_declaration'
  },
  CONTRACT_PENSION_UPDATE_DEATH_CLAUSE: {
    action: 'Prévoyance modification clause décès',
    category: 'Contrat',
    name: 'click_modification_death_clause'
  },
  CONTRACT_PENSION_HOSPI_UPDATE_PRO_INFO: {
    action: 'Prévoyance modification info pro',
    category: 'Contrat',
    name: 'click_modification_professional_information'
  },
  CONTRACT_ASSISTANCE_CONTACT_ASSISTANCE: {
    action: 'Assistance contact assistance',
    category: 'Contrat',
    name: 'click_contact_assistance'
  },
  CONTRACT_ASSISTANCE_ASSISTANCE_METRO: {
    action: 'Assistance Europ Assistance France',
    category: 'Contrat',
    name: 'click_contact_number_assistance_Fr'
  },
  CONTRACT_ASSISTANCE_ASSISTANCE_DROM: {
    action: 'Assistance Europ Assistance DROM',
    category: 'Contrat',
    name: 'click_contact_number_assistance_DROM'
  }
};
