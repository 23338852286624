import './index.less';
import './css/tailwind.css';
import '../node_modules/rc-switch/assets/index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import store from './store';
import GQLEnvironment from '@@graphql/Environment';
import Tokens from './lib/Tokens';
import { RelayEnvironmentProvider } from 'react-relay';
import QReferences from './_graphql/queries/QReferences';
import { buildUserSession } from '@@hooks/useKeycloakSession';

import { Provider } from 'react-redux';
import Keycloak from 'keycloak-js';
import { initGAnalytics } from '@@lib/ga';
const R = require('ramda');

const getDocumentRoot = () => {
  const element = document.createElement('div');
  document.body.appendChild(element);
  return element;
};

Tokens.setKey(process.env.API_USER_ID, process.env.API_KEY);

const kcConfig = async () => {
  const accessToken = await Tokens.getAccessToken();
  const response = await fetch('/keycloak/config', {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
  return response.json();
};

const kcPostSession = async ({ customerId, token, username, firstname, lastname, email }) => {
  const accessToken = await Tokens.getAccessToken();
  const response = await fetch('/keycloak/session', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      customerId,
      username,
      token,
      firstname,
      lastname,
      email
    })
  });
  return response.json();
};

const kcPutRefreshToken = async (username, token) => {
  const accessToken = await Tokens.getAccessToken();
  const response = await fetch('/keycloak/token', {
    method: 'PUT',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ username, token })
  });
  return response.json();
};

const kcGetUserInfo = async (kcToken) => {
  const accessToken = await Tokens.getAccessToken();
  const response = await fetch('/keycloak/me', {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'X-Authorization': `Bearer ${kcToken}`,
      'Content-Type': 'application/json'
    }
  });

  return response.json();
};

(async () => {
  const createSession = async (kc) => {
    const token = kc.token;
    const user = await kcGetUserInfo(token);
    store.getState().keycloakSession.user = user;
    await kcPostSession(buildUserSession(user, token));

    const intervalId = setInterval(async () => {
      const tokenRefreshed = await kc.updateToken(30);
      if (tokenRefreshed) {
        await kcPutRefreshToken(user.username, kc.token);
      }
    }, 30000);
    store.getState().refreshTokenIntervalId = intervalId;
  };

  const keycloakConfig = await kcConfig();

  const kc = new Keycloak(R.applySpec({
    url: R.prop('keycloakAuthUrl'),
    realm: R.prop('keycloakRealm'),
    clientId: R.prop('keycloakClientId')
  })(keycloakConfig));

  const authenticated = await kc.init({
    onLoad: 'check-sso',
    promiseType: 'native',
    redirectUri: window.location.origin,
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
  });

  store.getState().keycloakSession.keycloak = kc;
  clearInterval(store.getState().refreshTokenIntervalId);
  if (authenticated) {
    await createSession(kc);
  } else {
    await kcPostSession({
      token: null,
      username: null,
      customerId: null,
      firstName: null,
      lastName: null,
      email: null
    });
  }

  const environment = await GQLEnvironment();
  await initGAnalytics(environment);


  ReactDOM.render(
    <div>
      <RelayEnvironmentProvider environment={environment}>
        <QReferences>
          {({ references }) => (
            <Provider store={store}>
              <Routes references={references}/>
            </Provider>
          )}
        </QReferences>
      </RelayEnvironmentProvider>
    </div>,
    getDocumentRoot()
  );
})();

export {
  GQLEnvironment as environment
};
